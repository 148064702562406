define([
  'modules/common/subscribers/subscriber',

  'modules/profile/events/profile/afterLoad',
  'modules/profile/events/profile/unload',

  'modules/shop.cash-register-retail/components/menu',
], (
  Subscriber,
  AfterLoadEvent, UnloadEvent,
  MenuComponent,
) => {
  const onUnload = function (event) {
    MenuComponent.unload();
  };

  const onLoad = function (event) {
    MenuComponent.load();
  };

  return Subscriber.extend({
    events: [{
      event: AfterLoadEvent,
      action: onLoad,
    }, {
      event: UnloadEvent,
      action: onUnload,
    }],
  });
});
