define([
  'modules/common/subscribers/subscriber',

  'modules/profile/events/profile/afterLoad',

  'modules/shop.common/components/deviceConfig',
  'modules/shop.cash-register-retail/collections/checkoutButtons',
], (
  Subscriber,
  AfterLoadEvent,
  DeviceConfigComponent, CheckoutButtonsCollection,
) => {
  const onLoad = function (event) {
    CheckoutButtonsCollection.loadFromConfig();
  };

  return Subscriber.extend({
    events: [{
      event: AfterLoadEvent,
      action: onLoad,
    }],
  });
});
