define([
  'jquery',
  'underscore',
  'backbone',

  'modules/common/routers/aclRouter',
  'modules/common/acls/anyAcl',
  'modules/admin/acls/loggedOut',
  'modules/common/acls/routesAcl',
  'modules/shop.cash-register-retail/controllers/authentication',
  'modules/common/components/historyBreadcrumb',
], (
  $, _, Backbone,
  Router, AnyAcl, LoggedOutAcl, RoutesAcl, Controller, HistoryBreadcrumb,
) => Router.extend({

  controller: new Controller(),

  appRoutes: {
    'auth/logout': 'logout',
    'auth(/:swapTo)': 'authentication',
    'auth/loginByHash(/:data)': 'loginByHash',
  },

  acls: [
    new (AnyAcl.extend({
      error() {
        // force the index route, probably can be fixed better but no idea how
        Backbone.history.navigate(
          HistoryBreadcrumb.getIndexFragment(),
          { trigger: true },
        );
      },
    }))({
      acls: [
        new RoutesAcl({ routes: ['auth/logout', /auth\/loginByHash\/.*/] }),
        new LoggedOutAcl(),
      ],
    }),
  ],
}));
