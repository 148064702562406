define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/routers/aclRouter',
  'modules/upx/acls/loggedIn',
  'modules/shop.cash-register-retail/controllers/reload',
], (
  $, _, Backbone, Router, LoggedInAcl, Controller,
) => Router.extend({

  controller: new Controller(),

  appRoutes: {
    // The route name doesn't matter here. I only have it this way to catch all the routes
    'reload(/:route)(/:route)(/:route)(/:route)(/:route)(/:route)(/:route)(/:route)(/:route)(/:route)(/:route)(/:route)(/:route)(/:route)(/:route)(/:route)': 'reload',
    // Handle broken reloads for empty routes.
    'reload/': 'reload',
  },

  acls: [
    new LoggedInAcl(),
  ],

}));
