define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/routers/aclRouter',
  'modules/upx/acls/loggedIn',
  'modules/shop.cash-register-retail/controllers/repairs',
], (
  $, _, Backbone, Router, LoggedInAcl, Controller,
) => Router.extend({

  controller: new Controller(),

  appRoutes: {
    repairs: 'list',
    'repairs/create': 'create',
    'repairs/:id': 'get',
  },

  acls: [
    new LoggedInAcl(),
  ],

}));
