define([
  'application',
  'jquery',
  'backbone',

  'modules/common/subscribers/subscriber',
  'modules/profile/events/profile/load',
  'modules/profile/events/profile/afterLoad',
  'modules/profile/models/profile',
  'modules/common/components/locale',

  'modules/shop.cash-register-retail/collections/upx/DefaultShopConfigurationAttachedGroup',
  'modules/shop.cash-register-retail/models/giftCardPaymentProvider',
  'modules/shop.cash-register-retail/models/upx/DefaultShopConfiguration',
  'modules/shop.cash-register-retail/collections/upx/FeaturedAttribute',
  'modules/shop.common/components/deviceConfig',

  'modules/shop.cash-register-retail/collections/upx/PaymentProviderMethod',
  'modules/shop.cash-register-retail/collections/upx/PaymentProvider',
  'modules/shop.cash-register-retail/models/settings/paymentMethods',
  'modules/shop.cash-register-retail/components/feature',
  'modules/shop.cash-register-retail/models/settings/shopPos',
  'modules/shop.cash-register-retail/models/pinKey',

  'modules/shop.cash-register-retail/collections/upx/OnlineFoodOrderProvider',
  'modules/shop.cash-register-retail/components/pointOfSale',
  'modules/shop.cash-register-retail/models/ccv/ccvPin',
],
(
  App, $, Backbone, Subscriber,
  ProfileLoadEvent, ProfileAfterLoadEvent, ProfileModel, Locale,
  DefaultShopConfigurationAttachedGroup, GiftCardPaymentProviderModel, DefaultShopConfigurationModel, FeaturedAttributeCollection, DeviceConfig,
  PaymentProviderMethodCollection, PaymentProviderCollection, PaymentMethodsSetting, Feature, ShopPosSetting, PinKey,
  OnlineFoodOrderProvider, PointOfSale, CCVPin,
) => {
  const onLoggedIn = function (event) {
    const self = this;
    const subDef = $.Deferred();
    event.subDef = subDef;

    if (ProfileModel.has('relation_data_profile.language_iso2')) {
      Locale.setLocale(ProfileModel.get('relation_data_profile.language_iso2'));
    }

    $.when(
      GiftCardPaymentProviderModel.load(),
      FeaturedAttributeCollection.load(),
      DefaultShopConfigurationModel.load(),
      PaymentProviderMethodCollection.load(),
      PaymentProviderCollection.load(),
      PaymentMethodsSetting.load(),
      DeviceConfig.load(true),
      Feature.load(),
      OnlineFoodOrderProvider.load(),
      enablePinKey(),
    ).then(
      () => {
        $.when(
          ShopPosSetting.loadIfPossible(),
          DefaultShopConfigurationAttachedGroup.load(),
        ).then(subDef.resolve, subDef.reject);
      },
      subDef.reject,
    );

    $.when(subDef).then(
      () => {
        PointOfSale.pointOfSaleCheck();
        CCVPin.updatePinTerminal().then(() => {
          CCVPin.checkOpenTransaction();
        });

        self.options.preloadDef.resolve();
      },
      () => {
        self.options.preloadDef.reject();
      },
    );
  };

  let enablePinKey = () => {
    if (PinKey.isEnabled()) return new $.Deferred().resolve();

    return PinKey.registerDevice();
  };

  function afterLogon() {

  }

  return Subscriber.extend({
    events: [{
      event: ProfileLoadEvent,
      action: onLoggedIn,
    }, {
      event: ProfileAfterLoadEvent,
      action: afterLogon,
    }],
  });
});
