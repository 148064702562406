var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div class=\"customer full-child-height\" data-region=\"customer\"></div>\r\n<div class=\"product-defaults\" data-region=\"checkoutButtons\"></div>\r\n<div class=\"product-list designer-box rounded product-list-checkout\" data-region=\"product-list-and-search\"></div>\r\n<div class=\"product-total full-child-height\" data-region=\"product-totals\"></div>\r\n<div class=\"keypad designer-box rounded\" data-region=\"keypad\"></div>\r\n<div class=\"pay full-child-height\" data-ui=\"pay\">\r\n    <button class=\"btn btn-info btn-block designer-box rounded memory-btn\" data-action=\"memory\" data-ui=\"memory\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"memory",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":7,"column":113},"end":{"line":7,"column":142}}}))
    + "<br/><span class=\"order-count\"></span></button>\r\n    <button disabled class=\"btn btn-success btn-block designer-box rounded checkout-btn\" data-action=\"checkout\" data-ui=\"checkout\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"checkout",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":8,"column":131},"end":{"line":8,"column":162}}}))
    + "</button>\r\n    <button disabled class=\"btn btn-success btn-block designer-box rounded pay-btn\" data-action=\"pay\" data-ui=\"pay\"><i class=\"fal fa-credit-card\"></i> "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"pin",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":9,"column":151},"end":{"line":9,"column":177}}}))
    + "</button>\r\n</div>\r\n\r\n<div class=\"products-description designer-box rounded\" data-region=\"product-description\" style=\"display: none;\" data-ui=\"product-description\"></div>\r\n\r\n<div class=\"hidden\" data-region=\"popup\"></div>";
},"useData":true});