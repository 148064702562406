var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <option value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"group_member") : depth0)) != null ? lookupProperty(stack1,"relation_data_id") : stack1), depth0))
    + "\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"managed_subuser") : depth0)) != null ? lookupProperty(stack1,"relation_data_profile") : stack1)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</option>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"employee-selector\">\r\n    <label for=\"employee\" class=\"control-label\">\r\n        "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"employee",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":39}}}))
    + "\r\n    </label>\r\n    <select id=\"employee\" data-action=\"employee\" class=\"form-control \">\r\n        <option value=\"all\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"all",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":6,"column":28},"end":{"line":6,"column":54}}}))
    + "</option>\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"employee") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":8},"end":{"line":9,"column":17}}})) != null ? stack1 : "")
    + "    </select>\r\n</div>";
},"useData":true});