define([
  'application',
  'underscore',
  'backbone',
  'modules/common/subscribers/subscriber',

  'modules/shop.cash-register-retail/events/connection/backendConnected',
  'modules/shop.cash-register-retail/events/connection/backendDisconnected',
  'modules/shop.cash-register-retail/events/connection/internetConnected',
  'modules/shop.cash-register-retail/events/connection/internetDisconnected',

  'modules/common/components/connection',
  'modules/shop.cash-register-retail/components/noInternet',
  'modules/shop.cash-register-retail/models/connection',
],
(
  App, _, Backbone, Subscriber,
  BackendConnectedEvent, BackendDisconnectedEvent, InternetConnectedEvent, InternetDisconnectedEvent,
  ConnectionComponent, NoInternetComponents, ConnectionModel,
) => {
  function backendConnected() {
    ConnectionComponent.changeStatusFromType(ConnectionComponent.CELL_4G);
    NoInternetComponents.hideOverlay();
  }

  function backendDisconnected() {
    ConnectionComponent.changeStatusFromType(ConnectionComponent.NONE);
    NoInternetComponents.showOverlay(ConnectionModel);
  }

  function internetConnected() {
    ConnectionComponent.changeStatusFromType(ConnectionComponent.CELL_4G);
    NoInternetComponents.hideOverlay();
  }

  function internetDisconnected() {
    ConnectionComponent.changeStatusFromType(ConnectionComponent.NONE);
    NoInternetComponents.showOverlay(ConnectionModel);
  }

  return Subscriber.extend({
    events: [{
      event: BackendConnectedEvent,
      action: backendConnected,
    }, {
      event: BackendDisconnectedEvent,
      action: backendDisconnected,
    }, {
      event: InternetConnectedEvent,
      action: internetConnected,
    }, {
      event: InternetDisconnectedEvent,
      action: internetDisconnected,
    }],
  });
});
