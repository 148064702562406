define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/routers/aclRouter',
  'modules/upx/acls/loggedIn',
  'modules/shop.cash-register-retail/controllers/newsLetters',
], (
  $, _, Backbone, Router, LoggedInAcl, Controller,
) => Router.extend({

  controller: new Controller(),

  appRoutes: {
    'news-letters': 'overview',
    'news-letters/reload': 'reload',
  },

  acls: [
    new LoggedInAcl(),
  ],

}));
