define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/routers/aclRouter',
  'modules/upx/acls/loggedIn',
  'modules/shop.cash-register-retail/controllers/checkout',
], (
  $, _, Backbone, Router, LoggedInAcl, Controller,
) => Router.extend({

  controller: new Controller(),

  appRoutes: {
    '': 'checkout',
    checkout: 'checkout',
    'checkout/table/:table_id': 'checkoutTable',
    payment: 'payment',
    'quick-payment': 'quickPayment',
    'payment/tab/:tabId': 'paymentTab',
    'payment/table/:tabId': 'paymentTable',
    'payment/repair/:id': 'paymentRepair',
    success: 'success',
  },

  acls: [
    new LoggedInAcl(),
  ],

}));
