define([
  'application',
  'underscore',
  'backbone',
  'modules/common/components/managers/configuration',
  'modules/common/subscribers/subscriber',
  'modules/upx/events/user/loggedOut',
  'modules/upx/events/route/unauthorized',
  'modules/admin/events/header/signOut',

  'modules/common/components/historyBreadcrumb',
  'modules/profile/models/profile',
  'modules/admin/models/dashboard',
  'modules/common/components/managers/menu',
  'modules/upx/collections/users',

  'modules/shop.cash-register-retail/collections/upx/DefaultShopConfigurationAttachedGroup',
  'modules/shop.cash-register-retail/models/giftCardPaymentProvider',
  'modules/shop.cash-register-retail/models/upx/DefaultShopConfiguration',
  'modules/shop.cash-register-retail/collections/upx/FeaturedAttribute',
],
(App, _, Backbone, ConfigurationManager, Subscriber, LoggedOutEvent, UnauthorizedEvent, HeaderSignOutEvent,
  HistoryBreadcrumb, ProfileModel, DashboardModel, MenuManager, Users,
  DefaultShopConfigurationAttachedGroup, GiftCardPaymentProviderModel, DefaultShopConfigurationModel, FeaturedAttributeCollection) => {
  const onSignOut = function (event) {
    ProfileModel.unload();
    DashboardModel.unload();

    const menuItems = App.com(MenuManager).get('main');
    menuItems.remove(menuItems.models);

    DefaultShopConfigurationAttachedGroup.unload();
    GiftCardPaymentProviderModel.unload();
    FeaturedAttributeCollection.unload();
    DefaultShopConfigurationModel.unload();

    HistoryBreadcrumb.clean();
  };
  const onLoggedOut = function (event) {
    Backbone.history.navigate('auth', { trigger: true });

    ProfileModel.unload();
  };

  return Subscriber.extend({
    events: [{
      event: LoggedOutEvent,
      action: onLoggedOut,
    }, {
      event: UnauthorizedEvent,
      action: onLoggedOut,
    }, {
      event: HeaderSignOutEvent,
      action: onSignOut,
    }],
  });
});
