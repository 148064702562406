define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/subscribers/subscriber',

  'modules/upx/events/session/beforeRefresh',
  'modules/upx/events/session/noRefresh',
  'modules/upx/events/session/successfulRefresh',
  'modules/upx/events/session/failedRefresh',
  'modules/upx/events/session/afterRefresh',
], (
  $, _, Backbone, Subscribers,
  BeforeRefreshEvent, NoRefreshEvent, SuccessfulRefreshEvent, FailedRefreshEvent, AfterRefreshEvent,
) => {
  const type_before = 'before';
  const type_successful = 'successful';
  const type_failed = 'failed';

  function logEvent(type, event) {
    let message = '[SESSION] ';
    // Adding event based messages
    switch (type) {
      case type_before:
        message += 'Refreshing';
        break;
      case type_successful:
        message += 'Refresh successful';
        break;
      case type_failed:
        message += 'Failed to refresh ';
        if ('options' in event && 'error_message' in event.options) {
          message += `with message: "${event.options.error_message}"`;
        }
        break;
    }

    // Logging to console and Cash Regsiter Node API
    console.log(message);
  }

  return Subscribers.extend({
    events: [
      {
        event: BeforeRefreshEvent,
        action: (event) => { logEvent(type_before, event); },
      },
      {
        event: SuccessfulRefreshEvent,
        action: (event) => { logEvent(type_successful, event); },
      },
      {
        event: FailedRefreshEvent,
        action: (event) => { logEvent(type_failed, event); },
      },
    ],
  });
});
