define([
  'backbone',
  'modules/common/subscribers/subscriber',
  'modules/upx/events/error/auth',
  'modules/upx/collections/users',
],
(Backbone, Subscriber, AuthEvent, Users) => {
  const onAuthError = function () {
    const user = Users.findWhere({ active: true });

    if (user !== undefined) {
      user.logout();
    } else {
      // Checking if the route is already auth/signin
      const currentFragment = Backbone.history.location.hash;
      const isCurrentRouteAuthSigningRegex = /^#auth/;
      if (!isCurrentRouteAuthSigningRegex.test(currentFragment)) {
        Backbone.history.navigate('auth', { trigger: true });
      }
    }
  };

  return Subscriber.extend({
    events: [{
      event: AuthEvent,
      action: onAuthError,
    }],
  });
});
