define([
  'modules/common/subscribers/subscriber',
  'modules/pwa/events/worker/sourcesUpdate',
  'modules/common/components/locale',
  'toastr',
], (Subscriber, PwaSourceUpdate, Locale, Toastr) => {
  const onPwaUpdate = function (event) {
    Toastr.warning(
      Locale.translate('please_reload_the_page'),
      Locale.translate('new_version'),
      {
        timeout: 0,
        closeButton: true,
        onclick() {
          location.reload();
        },
      },
    );
  };

  return Subscriber.extend({
    events: [{
      event: PwaSourceUpdate,
      action: onPwaUpdate,
    }],
  });
});
