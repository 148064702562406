var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "        <h3 class=\"product-list-header\">"
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"order_items",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":5,"column":40},"end":{"line":5,"column":74}}}))
    + "</h3>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "        <div class=\"product-list-header\">\r\n            <button class=\"btn btn-primary w-50\" data-ui=\"now\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"now",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":8,"column":63},"end":{"line":8,"column":89}}}))
    + "</button>\r\n            <button class=\"btn btn-default btn-alt w-50\" data-ui=\"later\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"later",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":9,"column":73},"end":{"line":9,"column":101}}}))
    + "</button>\r\n        </div>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"product-blocks-container\" data-region=\"product-blocks-container\"></div>\r\n<div class=\"product-list-container\">\r\n    <div class=\"product-list-card-buttons\" data-region=\"card-buttons\"></div>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hospitalityTabs") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":11,"column":11}}})) != null ? stack1 : "")
    + "    <div data-region=\"product-list\" class=\"product-list full-child-height\" data-ui=\"product-list\"></div>\r\n    <div class=\"products-description\" data-region=\"product-description\" data-ui=\"product-description\" style=\"display: none;\"></div>\r\n    <div data-region=\"product-totals\" class=\"full-child-height\"></div>\r\n</div>\r\n<div class=\"pay full-child-height\" data-ui=\"pay\">\r\n    <div class=\"full-child-height tables-btn\" data-ui=\"table-button\"></div>\r\n    <button disabled class=\"btn btn-success btn-block designer-box rounded checkout-btn\" data-action=\"checkout\" data-ui=\"checkout\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"checkout",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":18,"column":131},"end":{"line":18,"column":162}}}))
    + "</button>\r\n    <button disabled class=\"btn btn-success btn-block designer-box rounded pay-btn\" data-action=\"pay\" data-ui=\"pay\"><i class=\"fal fa-credit-card\"></i> "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"pin",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":19,"column":151},"end":{"line":19,"column":177}}}))
    + "</button>\r\n</div>\r\n\r\n<div class=\"hidden\" data-region=\"popup\"></div>\r\n";
},"useData":true});